import http from './http.js'
// 所有国家
export const merchantCountry = (data) => http('merchant/country', data)

// 登录
export const login = (data) => http('auth/login', data, 'post')
// 商户管理/商户列表
export const merchantList = (data) => http('merchant/list', data)
// 商户管理/进件列表
export const scheduleList = (data) => http('schedule/list', data)
// 商户管理/详情
export const scheduleMerchant = (data) => http('schedule/merchant', data)

// 流水
export const merchantWater = (data) => http('merchant/water', data)

// 商户统计
export const merchantStatistics = (data) => http('merchant/statistics', data)

// 消息
export const messageList = (data) => http('message/list', data)
// 消息详情
export const messageRead = (data) => http('message/read', data, 'post')
// 获取有多少条未读信息
export const messageUnread = (data) => http('message/unread', data)

// 草稿箱
export const scheduleDrafts = (data) => http('schedule/drafts', data)
export const scheduleDrop = (data) => http('schedule/drop', data, 'delete')

// 进件
// 步骤1
export const scheduleInPost = (data) => http('schedule/in', data, 'post')
export const scheduleInPut = (data) => http('schedule/in', data, 'put')
// 银行卡
export const scheduleCardPost = (data) => http('schedule/card', data, 'post')
export const scheduleCardPut = (data) => http('schedule/card', data, 'put')
export const scheduleCardDelete = (data) => http('schedule/card', data, 'delete')

// 补充文档:上传文件
export const scheduleFiles = (data) => http('schedule/files', data, 'post')
// 补充文档:提交审核
export const scheduleDone = (data) => http('schedule/done', data, 'post')
// 步骤3
export const scheduleContractGet = (data) => http('schedule/contract', data)
export const scheduleContractPost = (data) => http('schedule/contract', data, 'post')
