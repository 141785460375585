import Axios from 'axios'
import { Message, Loading } from 'element-ui'
import Cookie from '@/utils/cookie'
import Router from '@/router'
// const qs = require('qs')
import qs from 'qs'
// 当前正在请求的数量
let requestCount = 0
let loading
// 显示loading
function startLoading (isLoading) {
    // 判断当前请求是否设置了显示Loading
    // if (!isLoading) return false
    // requestCount为0，才创建loading，避免重复创建
    if (requestCount === 0) {
        loading = Loading.service({
            lock: true,
            text: '加载中……',
            background: 'rgba(0, 0,0, 0.6)'
        })
    }
    requestCount++
}
// 隐藏loading
function endLoading (isLoading) {
    // if (!isLoading) return false
    requestCount--
    if (requestCount === 0) loading.close()
}

Axios.defaults.baseURL = process.env.VUE_APP_BASEURL// baseURL
Axios.defaults.timeout = 2000000// 设置请求超时
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

// 请求拦截
Axios.interceptors.request.use(config => {
    startLoading(config.headers.isLoading)
    // 在每次请求之前判断vuex中是否存在token,若存在在每一个请求header上都加上token
    const token = Cookie.getToken()
    if (token) config.headers.Authorization = token
    // console.log('请求ok', config)
    return config
}, error => {
    endLoading(error.config.headers.isLoading)
    // console.log('请求err', error)
    return Promise.error(error)
})

// 响应拦截
Axios.interceptors.response.use(response => {
    endLoading(response.config.headers.isLoading)
    // console.log('响应ok', response)
    return response
}, error => {
    endLoading(error.config.headers.isLoading)
    // console.log('响应err', error, error.response)
    return Promise.reject(error.response)
})

export default function http (url, params = {}, method = 'get') {
    return new Promise(resolve => {
        let promise
        // isLoading是否显示loading动画
        // 1.执行异步ajax请求
        if (method === "get" || method === "delete") {
            promise = Axios[method](url, { params })
        } else if (method === "post") {
            promise = Axios[method](url, qs.stringify(params))
        } else if (method === "put") {
            promise = Axios[method](url, qs.stringify(params), { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            // promise = Axios[method](url, params, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
            // promise = Axios[method](url, params)
            // promise = Axios[method](url, qs.stringify(params))
        }
        // switch (method) {
        //     case 'GET':
        //         promise = axios.get(url, { params })
        //         break;
        //     case 'DELETE':
        //         promise = axios.delete(url, { params })
        //         break;
        //     case 'POST':
        //         promise = axios.post(url, params)
        //         break;
        //     case 'PUT':
        //         promise = axios.put(url, params)
        //         break;
        //     default:
        //         return
        // }
        promise.then(response => {
            // 2.如果成功了，调用resolve(value),传入response
            // console.log('http,ok', response.data)
            // resolve(response.data)
            const code = response.data.code
            switch (code) {
                case 200:
                    resolve(response.data)
                    break;
                case 405:
                    message(response.data.error_message || '请重新登录')
                    Router.push(`/login`)
                    break;
                case 403:
                    message('当前商户您无权操作')
                    Router.back(-1)
                    break;
                default:
                    message(response.data.error_message || response.data.error)
            }
        }).catch(error => {
            // 3.如果失败了，不调用reject(error),而是提示异常信息
            // 如果调用reject就会触发catch，但是我们想要统一处理错误提示
            // console.log('http,err', error)
            message(error.statusText)
        })
    })
}

function message (message, type = 'error') {
    Message({
        message,
        type,
        duration: 8 * 1000
    })
}
